@import 'src/styles/imports';

.versionSelect {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  > fieldset {
      padding: 0;
      width: 50%;
      display: flex;
      flex-direction: row;
      background-color: $blue-tinted-100;
      border: 1px solid $grey-200;
      border-radius: 4px;
      align-items: center;
      &:focus-within {
        background-color: $blue-tinted-100;
        border: 1px solid $blue-500;
        transition: all 0.3s ease;
        svg {
          fill: $blue-500;
        }
      }
      select {
        // -moz-appearance: none; /* Firefox */
        // -webkit-appearance: none; /* Safari and Chrome */
        // appearance: none;
        // width: 100%;
        // background-color: transparent;
        // border: none;
        // transition: all 0.3s ease;
        // padding: 0.75rem;
        // font-size: 1rem;
        // color: $grey-900;

        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;

        &:hover {
          cursor: pointer;
        }
      }

      .dropdown {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        display: flex;
        justify-content: space-between;
        appearance: none;
        width: 100%;
        background-color: transparent;
        border: none;
        transition: all 0.3s ease;
        padding: 0.75rem;
        font-size: 1rem;
        color: $grey-900;
        z-index: 1;
      }

      // svg {
      //   margin-right: 10px;
      // }
  }
}

.ruleText {
  .amp {
    color: $grey-800;
    margin: 4px 0;
    padding: 0 20px;
  }

  p {
    margin: 0;
  }
}
