@import 'src/styles/imports';

.tutorialCard {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: $white;
  border-radius: 4px;
  width: max-content;
  height: fit-content;
  max-width: 380px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(#000, 0.1);

  .skipButton {
    @extend %textButton;
    color: $grey-800;
    margin-left: auto;
  }

  .tutorialContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    .tutorialTitle {
      font-weight: 900;
      font-size: 18pt;
    }
  }

  .navigation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previousButton {
      @extend %textButton;
      color: $blue-500;
      visibility: hidden;

      &.active {
        visibility: visible;
      }
    }
    .nextButton {
      @extend %primaryButton;
    }
  }
}

%primaryButton {
  padding: 0.5rem 2rem;
  background: $blue-500;
  border-radius: 99px;
  color: $white;
  font-size: 1rem;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: $blue-800;
  }
}

%textButton {
  padding: 0.5rem 0;
  background: none;
  border: none;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
  }
}
