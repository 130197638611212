@import 'src/styles/imports';

.glossary {
  width: 100%;
  height: 100%;
  background-color: $grey-background;
  overflow: auto;
  padding: 80px 40px 40px;
  display: flex;
  flex-direction: column;

  header {
    margin-bottom: 20px;
  }

  .glossaryGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .glossaryItems {
      display: grid;
      gap: 20px;
    }
  }
}
