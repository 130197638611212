//Blue
$blue-500: #204f88; //CTAs, Input Focus Border
$blue-600: #1d487c; //Planning Row Background
$blue-700: #183c67; //Planning Panel Confirmation Background, Save Button Background
$blue-800: #133053; //Button on Hover
$blue-900: #0f243e; //Toggle Button on Planning panel when bulk confirmation
$blue-tinted-000: #f4f6f9;
$blue-tinted-100: #e8eef4; //Input Focus Background
$blue-tinted-200: #c5d2df;
$blue-tinted-300: #a1b6ca;
$blue-ultralight: #f4f6f9; // - Delete
$blue-light: #a1b6ca; // - Delete
$blue-med: #00508c; // - Delete
$blue-dark: #024980; // - Delete
$blue-ultradark: #0f243e; // - Delete

//Blue
$blue-200: #3078cf; // Toggle button background
$blue-500: #204f88; //CTAs, Input Focus Border
$blue-600: #1d487c; //Planning Row Background
$blue-700: #183c67; //Planning Panel Confirmation Background, Save Button Background

$blue-900: #0f243e; //Toggle Button on Planning panel when bulk confirmation

$blue-tinted-100: #e8eef4; //Input Focus Background
$blue-tinted-200: #c5d2df;
$blue-tinted-300: #a1b6ca;

//$blue-ultralight: #f4f6f9; // - Delete ??????
// $blue-light: #a1b6ca; // - Delete ????
// $blue-med: #00508c; // - Delete
// $blue-dark: #024980; // - Delete
// $blue-ultradark: #0f243e; // - Delete ???

//Status
$yellow-tinted-suggested: #fefbf0;

// $green-ultralight: #f4f9f5; // - Delete
$green-tinted-success: #f5f9f5; //Browsing Panel enrolled card background

// $green-med: #38853c; // - Delete
$green-success: #38853c;

// $red-light: #f44336; // - Delete
$red-attention: #f44336;
$red-tinted-attention: #fff7f6;

$yellow-warning: #ff9800;

$blue-tinted-complete: #f4f6f9; //Browsing Panel graded card background

$purple-priorstudy: #604088;
$purple-tinted-priorstudy: #f7f5f9;
// Shades

// $black: #333; // - Delete
$black: #1a1a1a;
$grey-900: #333333; //Text Default
$grey-800: #747474; //Text Secondary
$grey-300: #c6c6c6; //Text Disabled
$grey-200: #d6d6d6; //Input Outline
$grey-light: #e8e8e8; // - Delete
$grey-100: #e8e8e8; //Key Line, Browsing Panel Card Border

//$grey-light: #e8e8e8; // - Delete
$grey-100: #e8e8e8; //Key Line, Browsing Panel Card Border

// $grey-ultralight: #fafafa; // - Delete
$grey-background: #fafafa; // Browsing Panel Background

$white: #fff;

//Area of study labels
$blue-label: #212d96;
$green-label: #2d6c7a;
$orange-label: #a7642a;
$yellow-label: #737439;
$pink-label: #86456c;
$purple-label: #604088;
