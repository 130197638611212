@import 'src/styles/imports';

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, -15px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.period {
  > label {
    margin-bottom: 10px;
    display: block;
  }
}

.blocks {
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
}

.mapFilters {
  display: flex;
  flex-direction: column;
  > label {
    margin: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: space-evenly;
    span {
      width: 50%;
      color: $grey-900;
      display: inline-flex;
      align-items: center;
    }
    > fieldset {
      padding: 0;
      width: 50%;
      display: flex;
      flex-direction: row;
      background-color: $blue-tinted-100;
      border: 1px solid $grey-200;
      border-radius: 4px;
      align-items: center;
      &:focus-within {
        background-color: $blue-tinted-100;
        border: 1px solid $blue-500;
        transition: all 0.3s ease;
        svg {
          fill: $blue-500;
        }
      }
      select {
        // -moz-appearance: none; /* Firefox */
        // -webkit-appearance: none; /* Safari and Chrome */
        // appearance: none;
        // width: 100%;
        // background-color: transparent;
        // border: none;
        // transition: all 0.3s ease;
        // padding: 0.75rem;
        // font-size: 1rem;
        // color: $grey-900;

        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;

        &:hover {
          cursor: pointer;
        }
      }

      .dropdown {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        display: flex;
        justify-content: space-between;
        appearance: none;
        width: 100%;
        background-color: transparent;
        border: none;
        transition: all 0.3s ease;
        padding: 0.75rem;
        font-size: 1rem;
        color: $grey-900;
        z-index: 1;
      }

      // svg {
      //   margin-right: 10px;
      // }
    }
  }
}
.progressionMapSection {
  width: 100%;

  .progressionMapTable {
    max-width: 100%;
    margin: 20px 0;
    padding: 20px;

    .progressionMapHeader {
      display: grid;
      grid-template-columns: auto min-content;

      .progressionMapTitle {
        margin-bottom: 30px;
        a {
          text-decoration: none;
          color: $blue-500;
          display: flex;
          align-items: center;
          margin-top: 8px;

          svg {
            margin-left: 10px;
          }
        }
      }
    }

    .menuIcon {
      align-self: start;
      svg {
        stroke: $black;
        height: 20px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .periodRow {
      margin-bottom: 20px;
      .yearAndSemesterLabel {
        margin: 10px 0;
      }
    }

    .notes {
      margin-top: 8px;
    }
  }

  .unit {
    height: 100%;
  }
}

.bulkAdd {
  flex-direction: column;
  display: flex;

  .add {
    display: grid;
    align-items: center;
    grid-template-columns: auto min-content;

    gap: 10px;
    border-bottom: 1px solid $grey-100;
    padding: 20px;

    &:last-of-type {
      border-bottom: none;
    }

    .secondaryButton {
      padding: 0.5rem 2rem;
      background: none;
      border-radius: 99px;
      color: $blue-500;
      font-size: 1rem;
      border: 1px solid $blue-500;
      display: grid;
      grid-column: auto;
      grid-auto-flow: column;
      column-gap: 0.5rem;
      align-items: center;
      line-height: 1;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }

      &:disabled {
        cursor: not-allowed;
        color: $grey-300;
        border: 1px solid $grey-300;
      }
    }
    .confirm {
      color: $green-success;
      display: flex;
      align-items: center;
      animation: fadeIn 0.3s 1 forwards ease-in-out;

      svg {
        stroke: $green-success;
        margin-right: 5px;
      }
    }
  }
}
