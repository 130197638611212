@import 'src/styles/imports';

.sidebar {
  background-color: #fff;
  width: 20vw;
  height: 100vh;
  overflow: auto;
  border-right: 1px solid $grey-100;
  padding-top: 60px;
  color: $grey-900;
  section {
    padding: 20px;

    & + section {
      border-top: 1px solid $grey-100;
    }

    label {
      color: $grey-800;
      font-size: 14px;
      display: block;
    }

    h3 {
      margin: 1rem 0;
      font-size: 1rem;
      text-transform: capitalize;
    }

    a {
      text-decoration: none;
      padding: 10px 0;
      margin: 10px 0;
      display: flex;
      align-items: center;
      color: inherit;
      transition: all 0s ease-in-out;
      transition-delay: 0.2s;
      flex-wrap: nowrap;
      z-index: 1;
      white-space: nowrap;

      span {
        white-space: nowrap;
      }

      svg {
        height: 20px;
        margin-right: 10px;
        transition: all 0s ease-in-out;
        transition-delay: 0.2s;
        flex: 0 0 auto;
      }

      .item {
        overflow: hidden;
        text-overflow: ellipsis;
        &.title {
          text-transform: capitalize;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -20px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: $blue-500;
        height: 100%;
        width: calc(100% + 20px);
        transform: translate(-100%, 0);
        transition: all 0.3s ease-in;
        z-index: -1;
      }

      &:hover {
        &:before {
          transform: translate(calc(-100% + 10px), 0);
        }
      }

      &[active] {
        color: #fff;
        transition-delay: 0;

        svg {
          transition-delay: 0;
          stroke: #fff;
        }

        &:before {
          transform: translate(0, 0);
        }
      }
    }
  }
}

// meta

.meta {
  button {
    border: 0;
    background: none;
    padding: 0;
    color: $blue-500;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

// plans

.plans {
}

// related pages

section.relatedPages {
  padding: 20px 0;

  label {
    margin-left: 20px;
  }
}
