@import 'src/styles/imports';

.star {
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  vertical-align: middle;
  line-height: 1;
  font-size: 2em;
  color: $grey-100;
  transition: color 0.2s ease-out;

  &:hover {
    cursor: pointer;
  }

  &.isSelected {
    color: $blue-500;
  }
}

.checkbox {
  @extend %visually-hidden;
}

%visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.starRow {
  color: $grey-900;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 30px 0;
}
