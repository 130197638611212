@import 'src/styles/imports';

.modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  left: 0;
  top: 0;
  transition: all 0.5s ease-in-out;

  .card {
    display: flex;
    flex-direction: column;
    z-index: 999;
    background-color: $white;
    width: 75vw;
    height: 75vh;
    max-height: 40rem;
    max-width: 60rem;
    padding: 3rem;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(#000, 0.1);
    transition: all 0.5s ease-in-out;
    animation: open 0.5s ease-in-out;
    gap: 20px;

    .head {
      display: flex;
      height: fit-content;
      justify-content: space-between;
      align-items: center;
      flex-grow: 0;
      width: 100%;

      .closeButton {
        &:hover {
          cursor: pointer;
        }
      }

      .title {
        margin: 0;
      }
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .action {
      text-align: center;
      
      .primaryButton {
        padding: 0.5rem 2rem;
        background: $blue-500;
        border-radius: 99px;
        color: $white;
        font-size: 1rem;
        border: none;
        cursor: pointer;
    
        &:disabled {
          background: $grey-300;
          color: $grey-800;
        }
      }
    }

    
  }

  .backDrop {
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }

  .hide {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
}

.slim {
  width: auto !important;
  padding: 20px !important;

  .content {
    align-items: flex-start !important;
  }  
}

@media screen and (max-width: 420px) {
  .modal {
    .card {
      padding: 1rem;
      width: 100vw;
      height: 100vh;
      max-height: 100%;
      max-width: 100%;
    }
    .backDrop {
      display: none;
    }
  }
}

@keyframes open {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
