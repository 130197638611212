@import 'src/styles/imports';

.containerMain {
  display: grid;
  grid-template-columns: 2fr 4fr 6fr;
  grid-gap: 6px;
  width: 180px;
  height: 100px;
  color: $white;
  font-weight: 900;

  .panel {
    background-color: $blue-light;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }

  .navPanel {
    display: grid;
    grid-template-rows: 30px 40px auto;
    grid-row-gap: 6px;
  }

  .active {
    background-color: $blue-500;
  }
}
