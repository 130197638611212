@import 'src/styles/imports';

.mapper {
  color: #fff;
}

.unitsHiddenContainer {
  padding: 20px;
}

.unitsHidden {
  padding: 10px 20px;
  background-color: $blue-700;
  border-radius: 4px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}
