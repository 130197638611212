@import 'src/styles/imports';

.planner {
  background-color: $blue-500;
  height: 100vh;
  overflow: visible;
  flex: 1 0 auto;
  transition: all 0.5s ease-in-out;
}

.plannerContent {
  padding-top: 60px;
  height: 100%;
  overflow: auto;
  color: #fff;
}

// edit mode

.editMode {
  background-color: $blue-700;
  border: 1px solid #fff;
  margin: 20px;
  border-radius: 4px;
}

.notEditMode {
  background-color: $blue-600;
}

.editModeHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 52px;
  > h3 {
    width: 100%;
    color: $white;
    text-align: center;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  button {
    border: 0;
    padding: 4px;
    background-color: transparent;
    cursor: pointer;
    svg {
      stroke: $white;
    }
  }
}

.inEditButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  row-gap: 10px;

  .confirmationButton {
    width: fit-content;
    background-color: #fff;
    border: 0;
    border-radius: 500px;
    font-weight: 500;
    font-size: 1rem;
    padding: 5px 20px;
    color: $blue-500;
    cursor: pointer;
  }
  .cancelButton {
    background-color: transparent;
    border: 0;
    padding: 5px 20px;
    color: $white;
    cursor: pointer;
  }
}

// change summary

.changeSummary {
  padding: 20px;
}

// delete popup
@keyframes dropIntoCentre {
  0% {
    opacity: 0;
    transform: translate(-50%, calc(-50% - 10px));
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.popupContainer {
  position: absolute;
  z-index: 8;
  background-color: rgba($black, 0.5);
  height: 100%;
  width: 100%;

  .popup {
    // position: absolute;
    z-index: 9;
    left: 50%;
    top: 40%;
    opacity: 0;
    transform: translate(-50%, calc(-50% - 10px));
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid $grey-100;
    box-shadow: 0 4px 16px rgba($black, 0.1);
    animation: dropIntoCentre 0.3s 1 forwards ease-in-out;
    // min-width: 300px;
    width: max(30px, 60%);

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h2 {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      svg {
        cursor: pointer;
      }
    }

    p {
      color: $grey-900;
    }

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      gap: 20px;

      .cancelButton {
        border: 0;
        background: none;
        font-size: 14px;
        padding: 0;
        color: $blue-500;
        cursor: pointer;
        font-weight: 600;
  
        &:hover {
          color: $blue-500;
          text-decoration: underline;
        }
      }
  
      .deleteButton {
        background-color: $red-attention;;
        border: 0;
        border-radius: 500px;
        padding: 6px 25px;
        color: $white;
        cursor: pointer;
        font-weight: 600;
  
        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }
}