@import 'src/styles/imports';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700;900&display=swap');

*,
:before,
:after {
  box-sizing: border-box;
  position: relative;
}

body,
html {
  min-height: 100vh;
  height: 100%;
}
// ::-webkit-scrollbar {
//   width: 0;
//   background: transparent;
// }

html {
  font-size: 16px;
}

body {
  line-height: 1.5;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  background-color: #fff;
}

.app,
#root {
  min-height: 100vh;
  height: 100%;
}

main {
  display: flex;
  width: 100%;
}

.drag-unit {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 4px 4px 16px rgba(#000, 0.3);
  hyphens: auto;
  // height: 160px;
  cursor: grabbing;

  h4 {
    margin: 0 0 10px;
  }

  .description {
    line-height: 1.25;
  }
}

p {
  margin: 0 0 20px;

  &:last-of-type {
    margin: 0;
  }
}

:focus {
  // outline: 1px solid yellow;
  outline: none;
}

:focus-visible {
  outline: revert;
}

h1 {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: 1rem * 1.5;
  font-weight: 900;
  line-height: 120%;
  color: $grey-900;
}

h2 {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: 1rem * 1.125;
  font-weight: 700;
  line-height: 125%;
  color: $grey-900;
}

h3 {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 125%;
  color: $grey-900;
}

// remove firebase emulator warning

.firebase-emulator-warning {
  display: none;
}
