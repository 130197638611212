@import 'src/styles/imports';

.welcomeIntro {
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .graphic {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4rem 0 2rem;
    }

    > h1 {
      text-align: center;
      font-weight: 900;
      font-size: 18pt;
      margin: 0 0 2rem;
    }

    > p {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      margin-bottom: 20px;
      max-width: 72ch;
    }
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dotsWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      &:hover {
        cursor: pointer;
      }
      .dot {
        background-color: $grey-300;
        margin: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;

        &.active {
          background-color: $blue-500;
        }
      }
    }

    .buttonNext {
      @extend %primaryButton;
      min-width: 180px;
      box-shadow: 0 4px 20px rgba(#000, 0.1);
    }
  }
}

%primaryButton {
  padding: 0.5rem 2rem;
  background: $blue-500;
  border-radius: 99px;
  color: $white;
  font-size: 1rem;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: $blue-800;
  }
}
