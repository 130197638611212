// responsive

@mixin res($size) {
  @if $size == m {
    @media only screen and (max-width: 720px) {
      @content;
    }
  }

  @if $size == d {
    @media only screen and (min-width: 721px) {
      @content;
    }
  }
}
