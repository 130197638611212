@import 'src/styles/imports';

.error {
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    > h2 {
      text-align: center;
      font-weight: 700;
      margin: 0 0 2rem;
    }

    > p {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      margin-bottom: 20px;
      max-width: 72ch;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .report {
      @extend %primaryButton;
      min-width: 180px;
      margin-bottom: 1rem;
    }
    .back {
      @extend %textButton;
      color: $blue-500;
    }
  }
}

%primaryButton {
  padding: 0.5rem 2rem;
  background: $blue-500;
  border-radius: 99px;
  color: $white;
  font-size: 1rem;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: $blue-800;
  }
}

%textButton {
  padding: 0.5rem 0;
  background: none;
  border: none;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
  }
}
