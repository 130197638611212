@import 'src/styles/imports';

.containerMain {
  width: 160px;
  height: 120px;

  .topCard {
    position: absolute;
    top: 0px;
    width: 160px;
    height: 56px;
    background-color: $blue-tinted-300;
    border-radius: 8px;
    animation: downUp 0.8s ease-in-out forwards;

    .lines {
      position: absolute;
      width: 80px;
      height: 4px;
      border-radius: 4px;
      background-color: $blue-tinted-100;
      top: 20px;
      left: 20px;

      &::after {
        content: ' ';
        position: absolute;
        width: 25px;
        height: 4px;
        background-color: $blue-tinted-100;
        top: 10px;
        border-radius: 5px;
      }
    }
  }
  .bottomCard {
    position: absolute;
    top: 64px;
    width: 160px;
    height: 56px;
    background-color: $blue-500;
    border-radius: 8px;
    animation: upDown 0.8s ease-in-out forwards;
    box-shadow: 0 4px 20px rgba(#000, 0.1);

    .lines {
      position: absolute;
      width: 80px;
      height: 4px;
      border-radius: 4px;
      background-color: $white;
      top: 20px;
      left: 20px;

      &::after {
        content: ' ';
        position: absolute;
        width: 25px;
        height: 4px;
        background-color: $white;
        top: 10px;
        border-radius: 5px;
      }
    }
  }
  .bottomDots {
    position: absolute;
    top: 28px;
    right: 44px;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background-color: $white;
    opacity: 0;
    animation: appear 0.5s ease-out 0.8s forwards;

    &::after {
      position: absolute;
      transform: translateX(+8px);
      content: ' ';
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background-color: $white;
    }
  }
  .iconBookmark {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    box-shadow: 0 4px 20px rgba(#000, 0.1);
    right: -22px;
    top: 5px;
    opacity: 0;
    animation: appear 0.5s ease-out 0.8s forwards,
      pop 1s infinite ease-in-out 0.8s;
    svg {
      width: 32px;
      height: 32px;
      stroke-width: 2px;
      stroke: $blue-500;
    }
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes downUp {
  0% {
    transform: translateY(0) scale(1);
  }

  30% {
    transform: scale(0.9);
  }

  100% {
    transform: translateY(64px) scale(1);
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0) scale(1);
  }

  30% {
    transform: scale(1.1);
  }

  100% {
    transform: translateY(-64px) scale(1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
