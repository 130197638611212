@import 'src/styles/imports';

.profileMenuButton {
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  color: #fff;
  font-weight: 900;
  margin-left: 30px;
  cursor: pointer;
}

// menu

.menu {
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  z-index: 9;
  right: 0;
  top: 100%;
  box-shadow: 4px 4px 16px rgba(#000, 0.3);
  padding: 0;
  overflow: hidden;

  .item {
    padding: 10px 20px 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $grey-100;

    .itemLabel {
      display: flex;
      white-space: nowrap;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }

    &:hover {
      background-color: $grey-background;
    }
  }
}

.item,
.itemLabel {
  svg {
    stroke: $grey-800;
  }
}

// header

.menuHeader {
  display: flex;
  align-items: center;
  padding: 20px;
}

.initials {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: $black;
  color: #fff;
  font-size: 20px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 2px $black;
}

.meta {
  padding-left: 10px;

  .email {
    color: $grey-800;
  }
}

.enter {
  animation: in 0.3s 1 forwards ease-in-out;
}

.exit {
  animation: out 0.3s 1 forwards ease-in-out;
}
