@import 'src/styles/imports';

.glossaryItem {
  transform: translate(0, -10px);
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $grey-100;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 1px rgba(#000, 0.1);
  animation: fadeIn 0.5s 1 forwards ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: 0;
  opacity: 0;
  > h2 {
    margin: 0 0 20px;
  }
}

.loadAnimation {
  animation: slideDown 0.5s forwards ease-in-out;
  animation-delay: 0.2s;
}

.unloadAnimation {
  animation: slideUp 0.5s forwards ease-in-out;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
