@import 'src/styles/imports';

.studentDetails {
  background-color: $blue-500;
  color: #fff;
  z-index: 2;

  h3 {
    color: $white;
    margin: 0;
    text-transform: capitalize;
  }
}

.meta {
  list-style: none;
  padding: 0 20px;
  margin: 0;
  display: grid;
  gap: 20px;

  label {
    font-size: 14px;
  }

  div {
    font-weight: 700;
  }
}
