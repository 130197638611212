@import 'src/styles/imports';

.containerMain {
  width: 160px;
  height: 120px;
  background-color: $blue-500;
  border-radius: 8px;
  animation: color 1s ease-in-out;

  .square {
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 8px;
    background-color: $blue-tinted-300;
    top: 50px;
    left: 24px;
    animation: appear 1.5s ease-in-out;
    animation: moveTopLeft 1.6s ease-in-out forwards;
  }

  .bookMark {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: #41699a;
    bottom: 20px;
    left: -64px;
    animation: appear 0.5s ease-in-out;
    animation: moveLeft 1s ease-in-out;
    svg {
      stroke: $white;
      stroke-width: 2px;
      width: 32px;
      height: 32px;
    }
  }

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #41699a;
    left: calc(50% - 32px / 2);
    top: -52px;
    animation: appear 0.8s ease-in-out;
    animation: moveUp 1s ease-in-out;
    svg {
      stroke: $white;
      stroke-width: 2px;
      width: 20px;
      height: 20px;
    }
  }
  .question {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: $blue-tinted-300;
    right: 8px;
    top: 28px;
    animation: appear 0.8s ease-in-out;
    animation: moveTopRight 1.6s ease-in-out forwards;
    svg {
      stroke: $white;
      stroke-width: 2px;
      width: 20px;
      height: 20px;
    }
  }
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 4px 20px rgba(#000, 0.1);
    right: -20px;
    bottom: 10px;
    opacity: 0;
    animation: appear 0.8s ease-in-out forwards 0.8s,
      moveRight 0.8s ease-in-out forwards, pop 1s ease-in-out infinite 0.8s;
    svg {
      stroke: $blue-500;
      stroke-width: 2px;
      width: 32px;
      height: 32px;
    }
  }
  .bottomDots {
    position: absolute;
    bottom: 30px;
    right: 44px;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background-color: $white;
    opacity: 0;
    animation: appear 0.8s ease-out 0.8s forwards;

    &::after {
      position: absolute;
      transform: translateX(+8px);
      content: ' ';
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background-color: $white;
    }
  }

  .topDots {
    position: absolute;
    top: 10px;
    left: 24px;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background-color: $white;
    &::before {
      position: absolute;
      transform: translateX(-8px);
      content: ' ';
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background-color: $white;
    }
    &::after {
      position: absolute;
      transform: translateX(8px);
      content: ' ';
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background-color: $white;
    }
  }
}

@keyframes color {
  0% {
    background-color: $white;
  }
  100% {
    background-color: $blue-500;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(60px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes moveTopLeft {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-60px, -60px);
  }
}

@keyframes moveTopRight {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(60px, -60px);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(-60px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(60px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
