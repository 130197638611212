@import 'src/styles/imports';

.tab {
  .overflowContainer {
    overflow-x: auto;
    .tabContainer {
      ul {
        display: flex;
        align-items: baseline;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid $grey-100;

        li {
          padding: 0;
          margin: 0 40px 0 0;
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 500;
          color: $grey-800;
          margin-bottom: 10px;

          &:hover {
            color: $blue-500;
          }
          &.active {
            color: $blue-500;
            font-weight: 700;
          }
        }
      }

      .indicator {
        position: absolute;
        bottom: 0;
        height: 6px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: $blue-500;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
