@import 'src/styles/imports';

.feedback {
  height: 100%;
  width: 100%;
  display: flex;
  margin-top: 60px;
  overflow: auto;
  //   justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $grey-background;

  section {
    background-color: #fff;
    border: 1px solid $grey-100;
    box-shadow: 0 1px 1px rgba($black, 0.3);
    border-radius: 4px;
    max-width: 800px;
    margin: 40px 20px;

    .feedbackTitle {
      padding: 40px;
    }
    .feedbackContent {
      padding: 40px;
      .feedbackType {
        > div {
          display: flex;
          flex-direction: row;
          margin: 30px 0;
          > label {
            margin-right: 20px;
            cursor: pointer;
            > input {
              margin-right: 10px;
            }
          }
        }
      }
      .feedbackForm {
        margin-top: 40px;
      }
      .bugForm {
        margin-top: 40px;
      }
    }
  }
  .submit {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }
  .primaryButton {
    padding: 0.5rem 2rem;
    background: $blue-500;
    border-radius: 99px;
    color: $white;
    font-size: 1rem;
    border: none;
    cursor: pointer;
  }
  .textBox {
    width: 100%;
    font-size: 1rem;
    padding: 10px;
    border: 1px solid $grey-100;
    border-radius: 4px;
    font-family: 'Helvetica Neue';
    &:focus {
      background-color: $blue-tinted-100;
      border: 1px solid $blue-500;
      transition: all 0.3s ease;
    }
  }
  .submitted {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $grey-900;
    padding-bottom: 40px;
    > div {
      padding: 40px 40px 0;
    }
  }
}
