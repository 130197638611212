@import 'src/styles/imports';

.containerMain {
  width: 160px;
  height: 120px;
  background-color: $blue-500;
  border-radius: 8px;

  //TODO Cherie: Refactor duplicated units

  .unitTop {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 5;
    content: ' ';
    bottom: 60px;
    left: 20px;
    background-color: $white;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    //   border: 3px solid #204f88;
    &::after {
      z-index: 6;
      content: ' ';
      position: absolute;
      bottom: 6px;
      background-color: $blue-500;
      width: 16px;
      height: 6px;
      border-radius: 20px;
    }
  }
  .unitBottomLeft {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 5;
    content: ' ';
    bottom: 20px;
    left: 20px;
    background-color: $white;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    //   border: 3px solid #204f88;
    &::after {
      z-index: 6;
      content: ' ';
      position: absolute;
      bottom: 6px;
      background-color: $blue-500;
      width: 16px;
      height: 6px;
      border-radius: 20px;
    }
  }
  .unitBottomRight {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 5;
    content: ' ';
    bottom: 20px;
    left: 60px;
    background-color: $white;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(#000, 0.1);
    transition: all 0.8 ease-in-out;
    animation: placeUnit 0.8s ease-in-out forwards,
      swing 0.8s ease-in-out forwards 0.8s;

    &::after {
      z-index: 6;
      content: ' ';
      position: absolute;
      bottom: 6px;
      background-color: $blue-500;
      width: 16px;
      height: 6px;
      border-radius: 20px;
    }
  }
}

@keyframes placeUnit {
  0% {
    transform: scale(2) translateY(-30px) translateX(30px);
  }
  100% {
    transform: scale(1) translateY(0) translateX(0);
  }
}

@keyframes swing {
  0% {
    transform: scale(1) rotate(-10deg);
  }

  50% {
    transform: scale(1.2) rotate(10deg);
  }

  100% {
    transform: scale(1) rotate(0deg);
  }
}
