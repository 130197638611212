@import 'src/styles/imports';

.preview {
  background-color: $blue-600;
  border-radius: 4px;
  padding: 10px;

  h3,
  h4 {
    color: #fff;
  }

  h3 {
    font-size: 0.5rem;
  }

  h4 {
    font-size: 0.4rem;
    margin: 0;
    font-weight: 500;
  }
}

.period {
  margin-top: 10px;
}

.blocks {
  display: grid;
  grid: auto-flow / repeat(4, 1fr);
  gap: 5px;
  margin-top: 5px;
}

.block {
  background-color: #fff;
  border-radius: 2px;
  font-size: 0.5rem;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;

  .content {
    padding: 4px;
    flex: 1 1 auto;
    hyphens: auto;
  }

  .name {
    font-weight: 700;
  }
}

// status

.status {
  flex: 0 0 auto;
  border-top: 1px solid $grey-100;
  padding: 4px;
  font-weight: 500;
  font-size: 0.4rem;

  .gradedPass,
  .gradedFail {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .gradedPass {
    color: $blue-600;
  }

  .gradedFail {
    color: $red-attention;
  }

  .enrolled {
    color: $green-success;
  }

  .notOffered,
  .discontinued {
    color: $red-attention;
  }

  .inPlanStatus {
    color: $purple-priorstudy;
  }
}

.comment {
  background-color: $blue-900;
  color: #fff;
  border: 1px solid $blue-tinted-300;
  box-shadow: 0px 1px 0px $blue-tinted-300;
}