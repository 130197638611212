@import 'src/styles/imports';
@import '../../../planner/mapper/blocks/blocks.module.scss';

.searchBox {
  form {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $grey-100;
    padding: 8px 10px;

    input {
      border: 0;
      font-size: 16px;
      color: $black;

      &::placeholder {
        color: $grey-800;
      }
    }

    svg {
      height: 20px;
    }
  }

  .results {
    position: absolute;
    width: 100%;
    background: $white;
    display: flex;
    flex-flow: column nowrap;
    border-radius: 4px;
    border: 1px solid $grey-100;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);

    .hit {
      padding: 20px;
      text-decoration: none;
      color: $grey-900;

      .metaTop {
        padding: 0;
      }

      &:hover {
        cursor: pointer;
        background: $blue-tinted-100;
      }
    }

    //.hit + .hit {
    //  padding-top: 0;
    //}

    .allResults {
      @extend .hit;
      color: $blue-500;
    }
  }

  .loading {
    @extend .results;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }
}
