@import 'src/styles/imports';

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.unitSearch {
  z-index: 11;
  position: fixed;
  left: 50%;
  top: 40%;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid $grey-200;
  padding: 20px;
  filter: drop-shadow(0 4px 16px rgba($black, 0.3));
  width: 100%;
  max-width: 400px;
  opacity: 0;
  transform: translate(0, -10px);
  animation: fadeIn 0.3s 1 forwards ease-in-out;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      color: $grey-800;
      font-size: 14px;
    }

    button {
      background-color: transparent;
      border: 0;
      display: flex;
      align-items: center;
      padding: 0;
      color: $blue-500;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      svg {
        height: 1rem;
        width: 1rem;
        stroke: $blue-500;
      }
    }
  }

  input {
    border: 1px solid $grey-200;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 1rem;
    display: block;
    margin-top: 10px;
    width: 100%;

    &::placeholder {
      color: $grey-800;
    }
  }

  ul {
    position: absolute;
    padding: 0;
    margin: 10px 0 0;
    background-color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
    border: 1px solid $grey-200;
    overflow: auto;
    max-height: 300px;
    overflow: auto;

    .loading {
      display: flex;
      justify-content: center;
    }

    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    li {
      padding: 5px 20px;
      color: $black;
      cursor: pointer;

      &:hover {
        background-color: $blue-tinted-000;
      }

      .code {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;

        span {
          font-size: 14px;
        }
      }

      .name {
        font-size: 14px;
      }
    }
  }
}
