@import 'src/styles/imports';

// screen

.loadingScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  color: $blue-600;
}

.message {
  margin-top: 40px;
}

// indicator

.loadingIndicator {
  display: grid;
  grid: repeat(3, 1fr) / repeat(3, 1fr);
  gap: 0;
  color: $blue-600;
}

.cube {
  float: left;
  animation: cubeGridScaleDelay 1.1s infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: 0.2s;
  }

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  &:nth-child(4) {
    animation-delay: 0.1s;
  }

  &:nth-child(5) {
    animation-delay: 0.2s;
  }

  &:nth-child(6) {
    animation-delay: 0.3s;
  }

  &:nth-child(7) {
    animation-delay: 0s;
  }

  &:nth-child(8) {
    animation-delay: 0.1s;
  }

  &:nth-child(9) {
    animation-delay: 0.2s;
  }
}

@keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale(1);
  }
  35% {
    transform: scale(0);
  }
}
