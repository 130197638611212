@import 'src/styles/imports';

.courseLabels {
  padding: 0 20px;
  color: #fff;
  z-index: 3;

  .header {
    display: flex;
    justify-content: space-between;
    h3 {
      color: $white;
      margin: 0;
    }
  }
  .listOfCourseLabels {
    .list {
      list-style: none;
      padding: 10px 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
    }
    .addButton {
      background-color: transparent;
      border: 0;
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      padding: 4px 0;
      svg {
        stroke: #fff;
        height: 1rem;
        margin-left: 5px;
      }
    }
  }
}
