@import 'src/styles/imports';

.search {
  &:focus-within {
    .suggested {
      opacity: 1;
      transform: translate(0, 0);
      visibility: visible;
    }
  }
  input {
    background-color: $blue-800;
    color: #fff;
    border: 0;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;

    &::placeholder {
      color: $blue-tinted-300;
    }
  }
  button {
    display: flex;
    align-items: center;
    border: 0;
    color: #fff;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    padding: 4px 2px;

    svg {
      stroke: #fff;
      height: 1rem;
    }

    &:hover {
      color: $grey-100;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.suggested {
  opacity: 0;
  visibility: hidden;
  max-height: 10rem;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  border-radius: 4px;
  background-color: $blue-800;
  color: $white;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(#000, 0.3);
  transition: all 0.3s ease-in-out;
  transform: translate(0, -10px);
  z-index: 2;

  li,
  .listHeader {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }

  li {
    cursor: pointer;
    &:hover {
      background-color: $blue-900;
    }
  }
}

.secondary {
  color: $blue-tinted-300;
}
