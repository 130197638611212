@import 'src/styles/imports';

.container {
  margin-top: 3rem;
  border: 1px solid $grey-100;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0;
  max-width: 100%;

  &:focus-within {
    background-color: $blue-tinted-100;
    border: 1px solid $blue-500;
    transition: all 0.3s ease;

    .icon svg path {
      stroke: $blue-500;
    }
  }

  .search {
    border: none;
    width: calc(100vw / 3 - 24px);
    background-color: transparent;
    font-size: 1rem;
    padding: 5px;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }
  &.error {
    border: 1px solid $red-attention;
    background-color: $white;
    .icon svg path {
      stroke: $red-attention;
    }
    &:after {
      content: 'No ID Found';
      position: absolute;
      bottom: -24px;
      color: $red-attention;
    }
  }
  &.loading {
    &:after {
      content: 'Finding student...';
      position: absolute;
      bottom: -24px;
    }
  }
}
// .right {
//   color: $grey-800;
// }

// .icon {
//   margin-left: 0.5rem;
// }

// .button {
//   background: $blue-500;
//   border-radius: 99px;
//   color: white;
//   border: none;
//   padding: 0.25rem 1rem;
//   margin-left: 1rem;

//   &:hover {
//     cursor: pointer;
//   }

//   &:active {
//     transform: translate(0, 2px);
//   }
// }
