@import 'src/styles/imports';

.landing {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $grey-background;
}

// recently viewed

.recentlyViewed {
  h3 {
    font-size: 1rem;
    margin: 60px 0 20px;
    padding: 0 10px;
    color: $grey-900;
  }

  .list {
    padding: 0;
    margin: 0;
    list-style: none;
    max-width: 100%;
    width: calc(100vw / 3 + 24px);

    li {
      padding: 6px 10px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $blue-tinted-100;
      }
    }

    .details {
      text-transform: capitalize;
    }
  }
}
