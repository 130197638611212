@import 'src/styles/imports';

.Toggle {
  width: 36px;
  height: 18px;
  border-radius: 500px;
  margin: 10px auto;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: grid;
  padding: 0;
  border: 0;

  &.on {
    background-color: $blue-200;

    .circle {
      transform: translate(18px, 0);
    }
  }

  &.off {
    background-color: $blue-900;
  }

  .circle {
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 100%;
    margin: 2px;
    transition: all 0.3s ease-in-out;
  }
}
