@import 'src/styles/imports';
@import '../../planner/mapper/blocks/blocks.module.scss';

.searchResults { 
    height: 100%;
    overflow: auto;
    padding: 80px 20px 20px;
}

.topText {
    margin-bottom: 40px;
}

.filters {
    color: $blue-500;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-weight: bold;
    margin: 10px 0;

    .filter {
        border-radius: 99px;
        background: $blue-tinted-100;
        padding: 4px 18px;
        margin: 0 12px 12px 0;
    }

    .filterButton {
        border-radius: 99px;
        background: $white;
        border: 2px solid $blue-500;
        padding: 2px 12px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
        }

        &:hover {
            cursor: pointer;
        }

        &:active {
            transform: translate(0, 2px);
          }
    }
}


.matches {
    display: flex;
    flex-flow: column wrap;

    .card {
        width: 100%;
        background: $white;
        border-radius: 4px;
        border: 1px solid $grey-100;
        padding: 20px;
        margin: 8px 0;
        text-decoration: none;
        color: $grey-900;
  
        .metaTop {
          padding: 0;
        }
  
        &:hover {
          cursor: pointer;
          background: $blue-tinted-100;
        }
      }
}

//filters
.filterList {
    height: 100%;
    width: 100%;

    .field {
        margin: 8px 0;
        
        input {
            margin-right: 12px;
        }

        .label {
            color: $grey-800;
            display: inline-block;
            margin: 10px 0;
        }

        .values {
            display: grid;
            grid: auto-flow / repeat(2, 1fr);
            gap: 16px;
        }
    }
}