@import 'src/styles/imports';

.view {
  padding: 80px 20px 20px;
  width: 100%;
  min-height: 100vh;
  background-color: $grey-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include res(m) {
    padding: 80px 0 0;
  }

  @media print {
    padding: 0;
    background-color: $white;
  }

  section {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid $grey-100;
    box-shadow: 0 1px 1px rgba($black, 0.3);
    width: 100%;
    max-width: 800px;
    margin-bottom: 40px;

    @include res(m) {
      box-shadow: none;
      margin-bottom: 20px;
    }

    @media print {
      margin-bottom: 0px;
      box-shadow: none;
      border: none;
      border-radius: 0px;
      margin-bottom: 0px;
      border-bottom: 1px solid $grey-100;
    }

    .content {
      padding: 0 20px;

      p {
        padding: 0;
      }

      .disclaimer {
        margin-top: 20px;
      }
    }

    h2 {
      margin: 0;
    }
  }
}

// info

.info {
  h3 {
    text-transform: capitalize;
    font-size: 1rem;
    margin: 0 0 20px;
  }

  .meta {
    display: grid;
    grid: auto-flow / repeat(2, 1fr);
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 20px;

    label {
      color: $grey-800;
      font-size: 14px;
    }

    div {
      font-weight: 700;
      font-size: 1rem;
    }
  }
}

// display options

.optionMessage {
  border-top: 1px solid $grey-100;
  margin: 20px 0;
  padding-top: 20px;

  p {
    font-style: italic;
    color: $grey-800;
    font-size: 14px;
  }

  .toggleOption {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    span {
      margin-right: 20px;
      flex: 1 1 auto;
    }

    .externalLink {
      text-decoration: none;
      color: $blue-500;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
  
      &:hover {
        text-decoration: underline;
      }

      span {
        margin: 0;
      }
    }
  }
}

// map

.disclaimer {
  font-style: italic;
  color: $grey-800;
  font-size: 14px;
}

.areasOfStudy,
.courseLabels {
  border-top: 1px solid $grey-100;
  padding-top: 20px;
  margin-top: 20px;

  label {
    color: $grey-800;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
}

.courseLabels {
  ul {
    li {
      padding: 0;
      margin: 0 8px 8px 0;
    }
  }
}

.plan {
  background-color: $blue-600;
  color: #fff;
  padding: 20px;
  border-radius: 4px;
  margin-top: 40px;

  @include res(m) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}

// guide

.graphic {
  background-color: $blue-600;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 4px;

  @include res(m) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }

  .block {
    background-color: #fff;
    color: $black;
    border-radius: 4px;
    width: 140px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px 0;

    > * {
      padding: 0 10px;
    }

    .aos {
      span {
        display: block;
        background-color: #ad1457;
        color: #fff;
        padding: 2px 5px;
        font-weight: 500;
        border-radius: 4px;
        font-size: 14px;
      }
    }

    h5,
    .courseLabel {
      margin: 0;
    }

    h5 {
      font-size: 1rem;
    }

    .courseLabel {
      font-size: 0.85rem;
      font-weight: 500;
      color: #2d6c7a;
    }

    .description {
      padding-bottom: 20px;
    }

    .status {
      padding-top: 10px;
      border-top: 1px solid $grey-100;
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      color: $blue-500;
    }
  }

  .tip {
    position: absolute;
    top: 50%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 400;
    font-size: 1rem;

    @include res(m) {
      white-space: pre-wrap;
    }

    svg {
      stroke: #fff;

      @include res(m) {
        display: none;
      }
    }

    &.left {
      right: 100%;
      transform: translate(-10px, -50%);

      svg {
        transform: rotate(180deg);
        margin-left: 10px;
      }
    }

    &.right {
      left: 100%;
      transform: translate(10px, -50%);

      svg {
        margin-right: 10px;
      }
    }
  }
}

// change summary

.changeSummary {
  color: $black;
  background-color: red;
}

// guide

.guide {
  dl {
    display: grid;
    grid: auto-flow / 1fr 4fr;
    gap: 0;

    dt,
    dd {
      border-top: 1px solid $grey-100;
      margin: 0;
      &:first-of-type {
        border-top: none;
      }
    }

    dt {
      padding: 20px 20px 20px 0;
      font-weight: 500;
    }

    dd {
      padding: 20px 0 20px 20px;
    }
  }

  .statusGlossary {
    .gradedPass {
      color: $blue-600;
    }

    .gradedFail {
      color: $red-attention;
    }

    .enrolled {
      color: $green-success;
    }

    .notOffered,
    .discontinued {
      color: $red-attention;
    }

    .inPlanStatus {
      color: $purple-priorstudy;
    }

    .suggested {
      color: $grey-900;
    }
    .granted {
      color: $purple-priorstudy;
    }

    .selected {
      text-align: center;
      border-radius: 4px;
    }
    .duplicate {
      color: $blue-500;
    }
    .unconfirm {
      color: $grey-900;
    }
    .invalid {
      color: $red-attention;
    }
  }
}

.footer {
  width: 100%;
  max-width: 760px;
  margin-bottom: 20px;
  text-align: center;
  color: $grey-800;
  font-size: 14px;

  @include res(m) {
    padding: 0 20px;
  }

  a {
    text-decoration: none;
    color: $grey-900;

    &:hover {
      text-decoration: underline;
    }
  }
}
