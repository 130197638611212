@import 'src/styles/imports';

// blocks container
.blocks {
  &.condense {
    padding: 4px 20px 8px;
    gap: 8px;
  }

  display: grid;
  grid: auto-flow / repeat(4, 1fr);
  gap: 10px;
  padding: 20px;

  > b {
    display: none;
  }
}

// blocks
.block {
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &.disabled {
    .handle {
      cursor: not-allowed;
    }

    .menuIcon {
      display: none;
    }
  }

  &.locked {
    .handle {
      cursor: default;
    }
  }

  &.nominated {
    .content {
      background-color: $blue-tinted-000;
    }
  }

  &.comment {
    .content {
      background-color: $blue-900;
      color: #fff;
      border: 1px solid $blue-tinted-300;
      box-shadow: 0px 1px 0px $blue-tinted-300;
      word-break: break-all;
    }

    textarea {
      font-family: inherit;
      font-size: inherit;
      background-color: inherit;
      border: none;
      width: 100%;
      height: 100%;
      color: inherit;
      z-index: 11;
    }

    .status {
      display: none;
    }
  }

  .content {
    &.condense {
      padding: 8px 0;
      row-gap: 4px;

      > * {
        padding: 0 8px;
      }

      h4 {
        line-height: 1;
        font-size: 16px;
      }
    }

    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px 0;
    height: 100%;
    border-radius: 4px;
    color: $grey-900;
    line-height: 1.25;
    hyphens: auto;
    text-overflow: ellipsis;
    background-color: #fff;
    border: 1px solid $grey-100;
    box-shadow: 0px 1px 0px $grey-100;
    overflow: hidden;

    > * {
      padding: 0 10px;
    }
  }

  h4 {
    margin: 0;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menuIcon {
    z-index: 3;
    position: absolute;
    right: 0;
    top: 8px;

    svg {
      stroke: $black;
      opacity: 0;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      height: 20px;
    }
  }

  &:hover {
    .menuIcon {
      svg {
        opacity: 1;
      }
    }
  }

  .description {
    flex: 1 1 auto;
    &.condense {
      font-size: 12px;
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:empty {
      padding: 0;
    }
    //word-break: break-all;
  }

  .electiveDescription {
    font-size: 12px;
    color: $grey-800;
  }

  // aos

  .aos {
    list-style: none;
    margin: 0;
  }

  // status

  .status {
    &.condense {
      font-size: 12px;
      padding-top: 8px;
      line-height: 1;
    }
    flex: 0 0 auto;
    border-top: 1px solid $grey-100;
    font-weight: 500;
    font-size: 14px;
    padding-top: 10px;

    .gradedPass,
    .gradedFail {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .gradedPass {
      color: $blue-600;
    }

    .gradedFail {
      color: $red-attention;
    }

    .enrolled {
      color: $green-success;
    }

    .notOffered,
    .discontinued {
      color: $red-attention;
    }

    .inPlanStatus {
      color: $purple-priorstudy;
    }

    .suggested {
      color: $grey-900;
    }
    .granted {
      color: $purple-priorstudy;
    }

    .selected {
      text-align: center;
      border-radius: 4px;
    }

    .duplicate {
      color: $blue-500;
    }

    .unconfirm {
      color: $grey-900;
    }

    .invalid {
      color: $red-attention;
    }
  }
}

.metaBottom {
  font-size: 14px;
  color: $grey-800;
}

.metaTop {
  font-size: 14px;
  color: $grey-800;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:empty {
    padding: 0;
  }
}

.metaTop {
  .type,
  .nominated {
    color: $blue-500;
  }
}

// map only

.map {
  .metaTop,
  .metaBottom {
    display: none;
  }
}

// browser + pMap
.browser,
.pMap {
  // transition: all 0.5s ease-in-out;
  &.suggested {
    .content {
      background-color: $yellow-tinted-suggested;
    }

    .status {
      color: $grey-900;

      > select {
        font-size: 1rem;
      }
    }
  }
  // &:hover {
  //   box-shadow: 0px 2px 8px $grey-200;
  // }
  &.enrolled {
    .content {
      background-color: $green-tinted-success;
    }
  }

  &.graded {
    .content {
      background-color: $blue-tinted-complete;
    }
  }
  &.inPlan {
    .content {
      background-color: $purple-tinted-priorstudy;
    }
  }
  &.notOffered,
  &.discontinued {
    background-color: $red-tinted-attention;
  }
  &.gradedFail {
    .content {
      background-color: $red-tinted-attention;
    }
  }
}

// progression map only

.pMap {
  .metaTop,
  .metaBottom {
    display: none;
  }

  .selector {
    z-index: 3;
  }
}

// browser only

// .browser {
//   &.suggested {
//     .status {
//       color: $grey-900;

//       > select {
//         font-size: 1rem;
//       }
//     }
//   }

//   &.enrolled {
//     .content {
//       background-color: $green-tinted-success;
//     }
//   }

//   &.graded {
//     .content {
//       background-color: $blue-tinted-100;
//     }
//   }
//   &.inPlan {
//     .content {
//       background-color: $purple-tinted-priorstudy;
//     }
//   }
// }

// advanced standings

.credit {
  .metaTop,
  .metaBottom {
    display: none;
  }
}

//eligible
.eligible {
  flex: 0 1 auto;
  color: $blue-500;
  font-weight: 500;
  font-size: 14px;
}

// major
.aos {
  &.condense {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    li {
      display: block;
      width: auto;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
    }
  }

  li {
    width: 100%;
    padding: 2px 5px;
  }

  .status {
    display: none;
  }
}

// new block
.newBlock {
  background-color: $blue-900;
  border: 1px solid #fff;
  color: #fff;

  .status {
    display: none;
  }
}

// selected
.selector {
  > select {
    width: 100%;
    border: none;
    color: $grey-900;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid $grey-100;
    background-color: transparent;

    &.withSelection {
      font-weight: 700;
    }
  }
}

.selected {
  background-color: $blue-900;
  color: $blue-900; //check this one
  border: 1px solid $grey-100;
  border-radius: 4px;

  .content {
    opacity: 0;
  }
}

// handle

.handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: grab;
}

.red {
  color: $red-attention;

  svg {
    fill: none;
    stroke: $red-attention !important;
  }
}

.grey {
  color: $grey-200;

  svg {
    fill: none;
    stroke: $grey-200 !important;
  }
}

// empty

.empty {
  &.condense {
    min-height: 80px;
    padding: 8px;
  }

  min-height: 160px;
  grid-column: 1/-1;
  background-color: $blue-900;
  border-radius: 4px;
  border: 1px solid $blue-tinted-300;
  padding: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  color: #fff;
  //pointer-events: none;

  .hidden {
    &.condense {
      padding: 0;
    }

    padding: 10px 20px;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

// toggle

.toggle {
  width: 36px;
  height: 18px;
  border-radius: 500px;
  margin: 10px auto;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &.on {
    background-color: $blue-200;

    .circle {
      transform: translate(18px, 0);
    }
  }

  &.off {
    background-color: $blue-900;
  }

  .circle {
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 100%;
    margin: 2px;
    transition: all 0.3s ease-in-out;
  }
}

.grow {
  //https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
  display: grid;

  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }

  > textarea {
    resize: none;
    overflow: hidden;
  }

  > textarea,
  &::after {
    grid-area: 1 / 1 / 2 / 2;
    word-break: break-all;
    line-height: inherit;
    padding: 0;
  }
}

.ineligible {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $grey-900;
  border-radius: 4px;
  opacity: 0.6;
  z-index: 2;
}
