@import 'src/styles/imports';

.courseLabelTag {
  background-color: $white;
  padding: 4px 10px;
  line-height: 1;
  white-space: nowrap;
  font-size: 14px;
  border-radius: 4px;
  margin: 0 4px 4px 0;
  display: grid;
  // flex: 0 0 auto;
  align-items: center;
  grid-template-columns: auto auto;
  column-gap: 4px;
  justify-content: space-between;
  font-weight: 700;
  max-width: 100%;

  &.inActive {
    background-color: rgba(0, 0, 0, 0.1);
    color: $grey-300 !important;
  }
}

.red {
  color: $red-attention;

  svg {
    fill: none;
    stroke: $red-attention !important;
  }
}
