@import 'src/styles/imports';

.containerMain {
  width: 160px;
  height: 120px;

  .overlayChatBoxLeft {
    z-index: 9999;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100px;
    height: 75px;
    background-color: $blue-500;
    animation: pop 1.6s ease-in-out infinite;
    box-shadow: 0 4px 20px rgba(#000, 0.1);

    .pointyBottom {
      position: absolute;
      width: 15px;
      height: 10px;
      left: 20px;
      bottom: -8px;
      clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
      background-color: $blue-500;
    }

    .lines {
      position: absolute;
      width: 40px;
      height: 4px;
      border-radius: 4px;
      background-color: $white;
      top: 30px;
      left: 20px;

      &::before {
        content: ' ';
        position: absolute;
        width: 60px;
        height: 4px;
        background-color: $white;
        top: -10px;
        border-radius: 5px;
      }

      &::after {
        content: ' ';
        position: absolute;
        width: 20px;
        height: 4px;
        background-color: $white;
        top: 10px;
        border-radius: 5px;
      }
    }
  }
  .chatBoxRight {
    position: absolute;
    border-radius: 16px;
    top: 30px;
    right: 0px;
    width: 100px;
    height: 75px;
    border-radius: 16px;
    background-color: $blue-tinted-300;
    animation: pop 1.6s ease-in-out infinite 0.5s;

    .pointyBottom {
      position: absolute;
      width: 15px;
      height: 10px;
      right: 20px;
      bottom: -8px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
      background-color: $blue-tinted-300;
    }

    .lines {
      position: absolute;
      width: 40px;
      height: 4px;
      border-radius: 4px;
      background-color: $white;
      top: 30px;
      left: 20px;

      &::before {
        content: ' ';
        position: absolute;
        width: 60px;
        height: 4px;
        background-color: $blue-tinted-100;
        top: -10px;
        border-radius: 5px;
      }

      &::after {
        content: ' ';
        position: absolute;
        width: 20px;
        height: 4px;
        background-color: $blue-tinted-100;
        top: 10px;
        border-radius: 5px;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
