@import 'src/styles/imports';

.collapsible {
  margin-top: 10px;
}

.header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;

  .toggle {
    display: block;
    height: 20px;
    transition: all 0.3s ease-in-out;
    margin-right: 10px;

    svg {
      height: 20px;
    }
  }
}

.body {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.content {
  transition: all 0.3s ease-in-out;
  padding: 0 10px 10px 20px;

  a {
    margin: 0;
  }
}
