@import 'src/styles/imports';

.tutorial {
  position: absolute;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  left: 0;
  top: 0;

  .tutorialModal {
    z-index: 999;
    position: absolute;
    animation: open 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    &.hide {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }
    // .pointer {
    //   position: absolute;
    //   top: 50%;
    //   z-index: 999;
    //   border-top: 10px solid transparent;
    //   border-bottom: 10px solid transparent;
    //   border-left: 10px solid $white;
    // }
  }
}
.highlightedArea {
  position: absolute;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.63);
}

@keyframes open {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
