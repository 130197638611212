@import 'src/styles/imports';

.ContextMenu {
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow: visible;

  menu {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 4px 4px 16px rgba(#000, 0.3);
    display: inline-block;
    padding: 0;
    // overflow: hidden;
    font-size: 14px;
  }
}

.SubMenu {
  overflow: visible;
  width: 100%;
  position: absolute;
  left: 0;
  top: -14px;

  menu {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

.item {
  white-space: nowrap;
  padding: 10px 20px 10px 10px;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background-color: $blue-tinted-000;

    .SubMenu {
      menu {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    vertical-align: middle;
    top: -2px;
    stroke: currentColor;
    stroke-width: 1.5;
  }
}

.disabledItem {
  cursor: default;
  &:hover {
    background-color: #fff;
  }
}

.break {
  width: 100%;
  height: 1px;
  background-color: #eee;
}
