@import 'src/styles/imports';

.creditPointsTag {
  &.condense {
    padding: 0 4px;
    font-size: 12px;
  }
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: $blue-500;
  background-color: $blue-tinted-100;
  border: 1px solid $blue-tinted-200;
  border-radius: 50px;
  padding: 2px 6px;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI';
}
