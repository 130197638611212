@import 'src/styles/imports';

// advanced standings

.advancedStandings {
  h3 {
    color: #fff;
    font-size: 18px;
    margin: 0 0 20px;
  }
}

// year

.year {
  h3 {
    color: #fff;
    font-size: 18px;
    margin: 0 0 20px;
  }

  &:last-of-type .period:last-of-type .blocks:last-of-type {
    margin-bottom: 10px;
  }
}

// period

.period {
  h4 {
    font-size: 14px;
    font-weight: 400;
  }
}

// block

.blocks {
  display: grid;
  grid: auto-flow / repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 40px;

  @include res(m) {
    grid: auto-flow / repeat(2, 1fr);
  }
}

.blockContainer {
  display: flex;
  flex-direction: column;
}

.block {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid $grey-100;
  color: $black;
  line-height: 1.25;
  hyphens: auto;
  text-overflow: ellipsis;
  background-color: #fff;
  border: 1px solid $grey-100;
  box-shadow: 0px 1px 0px $grey-100;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  row-gap: 10px;
  padding: 10px 0;

  > * {
    padding: 0 10px;
  }

  h5 {
    font-size: 1rem;
    margin: 0;
  }

  .tags {
    &:empty {
      padding: 0;
    }
  }

  &.comment {
    background-color: $blue-800;
    color: #fff;

    .description {
      font-weight: 400;
      overflow-wrap: anywhere;

      a {
        color: #fff;
      }
    }

    .status {
      display: none;
    }
  }
}

.aos {
  list-style: none;
  margin: 0;

  // li {
  //   padding: 2px 5px;
  //   line-height: 1;
  //   white-space: nowrap;
  //   font-size: 14px;
  //   border-radius: 4px;
  //   margin: 0 4px 4px 0;
  //   font-weight: 500;
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  // }
}

.description {
  font-weight: 400;
  flex: 1 0 auto;
}

.status {
  flex: 0 0 auto;
  border-top: 1px solid $grey-100;
  padding-top: 10px;
  font-weight: 500;
  font-size: 14px;

  .gradedPass,
  .gradedFail {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .gradedPass {
    color: $blue-600;
  }

  .gradedFail {
    color: $red-attention;
  }

  .enrolled {
    color: $green-success;
  }

  .notOffered,
  .discontinued {
    color: $red-attention;
  }

  .inPlanStatus {
    color: $purple-priorstudy;
  }

  .suggested {
    color: $grey-900;
  }
  .granted {
    color: $purple-priorstudy;
  }

  .selected {
    text-align: center;
    border-radius: 4px;
  }
  .duplicate {
    color: $blue-500;
  }
  .unconfirm {
    color: $grey-900;
  }
  .invalid {
    color: $red-attention;
  }
}

.empty {
  padding: 10px 20px;
  background-color: $blue-700;
  border-radius: 4px;
  text-align: center;
}

// note

$yellow: #fef49c;

@keyframes dropIn {
  from {
    opacity: 0;
    transform: translate(0, -5px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.Note {
  background-color: $yellow;
  padding: 6px 10px;
  border-radius: 4px;
  color: $black;
  margin-top: 6px;
  border-bottom: 2px solid rgba(#000, 0.3);
  filter: drop-shadow(0px 1px 0px rgba(#000, 0.3));
  animation: dropIn 0.5s forwards ease-in-out;
}

.poke {
  width: 10px;
  height: 10px;
  background-color: $yellow;
  position: absolute;
  left: calc(50% - 5px);
  top: -5px;
  transform: rotate(45deg);
}

.noteContent {
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-wrap: anywhere;

  a {
    color: $blue-500;
  }
}
