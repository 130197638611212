@import 'src/styles/imports';

.containerMain {
  width: 160px;
  height: 120px;
  background-color: $blue-500;
  border-radius: 8px;

  .typeField {
    position: absolute;
    width: 120px;
    height: 20px;
    border-radius: 4px;
    background-color: $white;
    top: 20px;
    left: 20px;
    transition: all 0.8s ease-in-out;
    animation: widthIncrease 0.8s ease-in-out forwards;

    &::before {
      position: absolute;
      content: ' ';
      top: calc(50% - 12px / 2);
      width: 3px;
      height: 12px;
      background-color: $blue-500;
      left: 6px;
      border-radius: 5px;
    }
  }
  .lines {
    position: absolute;
    width: 80px;
    height: 4px;
    border-radius: 4px;
    background-color: $blue-tinted-300;
    top: 40px;
    transition: all 0.8s ease-in-out;
    animation: widthIncrease 0.8s ease-in-out forwards;

    &::after {
      content: ' ';
      position: absolute;
      width: 25px;
      height: 4px;
      background-color: $blue-tinted-300;
      top: 10px;
      border-radius: 5px;
      transition: all 0.8s ease-in-out;
      animation: widthIncrease 0.8s ease-in-out forwards;
    }
  }
  .bottomDots {
    position: absolute;
    bottom: 30px;
    right: 44px;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background-color: $white;
    opacity: 0;
    animation: appear 0.5s ease-out 0.5s forwards;

    &::after {
      position: absolute;
      transform: translateX(+8px);
      content: ' ';
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background-color: $white;
    }
  }
  .search {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    box-shadow: 0 4px 20px rgba(#000, 0.1);
    right: -22px;
    bottom: 10px;
    opacity: 0;
    animation: appear 0.5s ease-out 0.5s forwards,
      pop 1s infinite ease-in-out 0.5s;
    svg {
      stroke: $blue-500;
      stroke-width: 2px;
      width: 28px;
      height: 28px;
    }
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes widthIncrease {
  0% {
    width: 10%;
  }

  100% {
    width: 100% '';
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
