@import 'src/styles/imports';

.TextArea {
  //https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
  display: grid;

  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }

  > textarea {
    resize: none;
    overflow: hidden;
  }

  > textarea,
  &::after {
    grid-area: 1 / 1 / 2 / 2;
    word-break: break-all;
    line-height: inherit;
    padding: 0;
  }
}
