@import 'src/styles/imports';
.planNameContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  > div {
    width: 100%;
    justify-content: space-between;
  }
  h2 {
    padding: 10px 20px;
    font-size: 16px;
    margin: 0;
    cursor: grab;
    color: $white;
    width: 100%;
    justify-content: space-between;

    form {
      width: 100%;
      input {
        background-color: $blue-800;
        color: #fff;
        border: 0;
        border-radius: 4px;
        padding: 10px;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        &.error {
          outline: 2px solid $red-attention;
        }
      }
    }
  }

  .rightMargin {
    margin-right: 20px;
  }
}

.enter {
  animation: in 0.3s 1 forwards ease-in-out;
}

.exit {
  animation: out 0.3s 1 forwards ease-in-out;
}
