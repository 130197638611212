@import 'src/styles/imports';

.errorMessage {
  position: absolute;
  top: 70px;
  padding: 0 20px;
  z-index: 3;
  width: fit-content;

  .box {
    padding: 10px;
    width: fit-content;
    border-radius: 4px;
    background-color: $white;
    color: $red-attention;
    box-shadow: 4px 4px 16px rgba(#000, 0.3);
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 5px;

    svg {
      stroke: $red-attention;
      align-self: center;
      justify-self: center;
    }

    .poke {
      width: 10px;
      height: 10px;
      background-color: $white;
      position: absolute;
      left: 40px;
      top: -5px;
      transform: rotate(45deg);
    }
  }
}
