@import 'src/styles/imports';

.progress {
  margin: 20px 0 40px;

  .bar {
    height: 14px;
    border-radius: 20px;
    background-color: $grey-background;
    box-shadow: inset 0 0 0 1px $grey-100;
  }

  .blob {
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 20px;
    z-index: 2;
    border: 1px solid white;
    animation: grow 0.75s ease-in-out forwards;
    padding-right: 6px;
  }
}
.key {
  display: flex;
  flex-flow: row wrap;
}

.keyItem {
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
  font-size: 14px;
}

.keyBlob {
  display: inline-block;
  height: 10px;
  width: 1rem;
  margin: 0 5px 0 0;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px $grey-100;
}

.labels {
  width: 100%;
  display: flex;
  font-size: 12px;
  color: $grey-800;
  margin-top: 8px;
  margin-bottom: 10px;

  > span {
    width: 1px;
    display: flex;
    justify-content: center;
    position: absolute;

    &::before {
      content: '';
      width: 1px;
      height: 4px;
      background-color: $grey-200;
      position: absolute;
      left: calc(50% - 0.5px);
      top: -4px;
    }

    // &:first-of-type {
    //   justify-content: flex-start;
    // }
    // &:last-of-type {
    //   justify-content: flex-end;
    // }
    // require design update for extreme cases with more than 192 credit points
  }
}

@keyframes grow {
  0% {
    width: 0;
    //left: 0%;
  }
}
