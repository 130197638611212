@import 'src/styles/imports';

.accordion + .accordion {
  border-top: 1px solid $grey-100;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  width: 100%;
  background-color: inherit;
  border: 0;
  text-align: left;
  color: inherit;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .headerContent {
    width: 100%;
  }
}

.toggle {
  display: block;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.3s linear;
  @media print {
    display: none;
  }

  svg {
    fill: currentColor;
  }
}

.body {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  @media print {
    height: fit-content !important;
  }
}

.content {
  padding: 20px 0;
  transition: all 0.3s ease-in-out;

  p {
    margin: 0 0 20px;
    padding: 0 20px;

    &:last-of-type {
      margin: 0;
    }
  }
}
