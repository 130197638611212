@import 'src/styles/imports';

.autosave {
  > div {
    display: flex;
    align-items: center;

    &.idle {
      opacity: 0;
      transition: all 1s ease-in-out;
    }

    &.saving {
      transition: all 0.3s ease-in-out;
      opacity: 1;
    }
  }

  .text {
    margin-left: 10px;
  }
}
