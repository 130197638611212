@import 'src/styles/imports';

.browser {
  background-color: $grey-background;
  height: 100vh;
  flex: 1 0 auto;
  display: grid;
  grid: 2fr / 20vw 1fr;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid $grey-100;

  .left {
    * {
      padding: 0 0.5rem;
    }

    .title {
      font-weight: bold;
      border-left: 1px solid $grey-100;
    }
  }

  .right {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;

    .user {
      margin: 0 0.5rem 0 2rem;
    }
  }
}

.nav {
}

.handbook {
  width: 100%;
}
