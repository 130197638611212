@import 'src/styles/imports';

.hoverMenu {
  color: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    .buttons {
      opacity: 1;
    }
  }
}

.buttons {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  display: flex;
  z-index: 3;

  button {
    border: 0;
    border-radius: 4px;
    padding: 4px;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(#000, 0.3);
    }

    svg {
      stroke: currentColor;
    }
  }
}
