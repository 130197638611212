@import 'src/styles/imports';

// section

.container {
  border: 1px solid $grey-100;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(#000, 0.3);
  margin-bottom: 20px;
}

.section {
  border-top: 1px solid $grey-100;

  h2 {
    margin-bottom: 0;
    padding-right: 20px;
    font-size: 16px;
  }

  .sectionContents {
    padding: 20px;
    overflow-wrap: break-all;
    width: 100%;

    a {
      text-decoration: none;
      color: $blue-500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.list {
  display: grid;
  padding: 20px;
  grid: auto-flow / repeat(2, 1fr);
  gap: 10px;
}

.accordionHeader {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .cp {
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
  }
}

.description {
  padding: 0 20px;
}

// blocks

.HandbookBlocks {
  padding: 20px;
  display: grid;
  gap: 10px;
}
